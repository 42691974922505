import EndUsersAccountsTable from './tables';
import useToast from '@fuse/hooks/useToast'
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { usePermission } from '@fuse/hooks';
import { setSerialNumberForServiceRequest } from '../store/filterParamsReducer';
import { openFormViewerDialog,closeFormViewerDialog } from '../store/formViewDialogReducer';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
import { generateProperLocationString } from '@fuse/utils/general';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [page, setPage] = useState(0);
	const { hasPermission } = usePermission();
	const viewServiceRequestPermission = hasPermission('view-service_request')
	const createServiceRequestPermission = hasPermission('add-service_request')
	const updateServiceRequestPermission = hasPermission('update-service_request')
	const deleteServiceRequestPermission = hasPermission('delete-service_request')
	const [loading, setLoading] = useState(false);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const [selectedTab, setSelectedTab] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [currentSearch, setCurrentSearch] = useState('_');
	const [serviceRequest, setServiceRequest] = useState([]);
	const serial_number = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.serial_number)
	let [totalRecords,setTotalRecords] = useState(0);
	const [ logsLoading,setLogsLoading ] = useState(false)

	function getServiceRequest(){
		axios.get(`/service/show-all-service-request?archived=${true}`).then((res) => {
			const { response } = res.data.data;
			setServiceRequest(formatData(response))
			setTotalRecords(response?.length)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false)
		});
	}

	function formatData(data){
		return data.map((item) => {
			const tempDescription = item.description;
			const tempElement = document.createElement('div');
			tempElement.innerHTML = tempDescription;
			item.description = tempElement.textContent
			return {
				id: item.id,
				ticket_id: item.ticket_id,
				asset_id: item.asset_id,
				archived_asset: item.asset?.deleted_at ? true : false,
				status: item.service_request_status ? item.service_request_status?.status_name : 'N/A', 
				status_details: {
					id: item?.service_request_status?.id,
					status_name: item?.service_request_status?.status_name,
					status_color: item?.service_request_status?.status_color
				},
				serial_number: item.asset_id ? item.asset.serial_number : 'No Serial Number',
				subject_line: item.title,
				location: item.asset_id ? 
					generateProperLocationString({
						location_name: item.asset.shipping.location_name.trim(),
						address1: item.asset.shipping.address1.trim() ?? '',
						address2: (item.asset.shipping.address2 ?? '').trim(),
						city: item.asset.shipping.city.trim() ?? '',
						state: item.asset.shipping.state.trim() ?? '',
						country: item.asset.shipping.country.trim() ?? ''
					}): 'No Location Name',
				detailed_description_of_the_issue: item.description,
				created_date: item.created_at ? dateReformatTo_mmm_dd_yyyy(item.created_at) : '-',
			}
		})
	}

	useEffect(()=>{
		setLoading(true)
		getServiceRequest()

		if(serial_number){
			dispatch(openFormViewerDialog())
		}

		//call on component unmount
		return(()=>{
			dispatch(setSerialNumberForServiceRequest(null))
			dispatch(closeFormViewerDialog())
		})
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	return (
		<div>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentWrapper : classes.contentWrapper
				}}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							<EndUsersAccountsTable 
								fetchServiceReq={getServiceRequest}
								serviceRequests={serviceRequest}
								totalRecords={totalRecords}
								page={page}
								setPage={setPage}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								loading={loading}
								viewServiceRequestPermission = {viewServiceRequestPermission}
								createServiceRequestPermission = {createServiceRequestPermission}
								updateServiceRequestPermission = {updateServiceRequestPermission}
								deleteServiceRequestPermission = {deleteServiceRequestPermission}
							/>
						</div>
					</>
				}
				innerScroll
			/>
		</div>
	)
}

export default withReducer('ArchivedServiceRequestsApp', reducer)(CardedFullWidth2TabbedSample);
