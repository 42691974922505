import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Button } from '@material-ui/core';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setfilterlist, setrowGroupModel,setServiceRequestId as  setServiceRequestID} from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ServiceRequestDailog from './ServiceRequestDailog';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height:"100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin:'5px 5px 5px 0',
		height:'28px'
	},
	activeTab: {
		background:"rgba(107, 44, 87, 0.04);",
		borderRadius:"0",
	},
	buttonSquare : {
		borderRadius:'5px'
	},
	btn:{
		background:"#30C90A",
		width:"120px",
		borderRadius:"17px",
		color:"#fff"
	},
	icon:{
		fontSize:"18px",
		color:"#61656F",
		display:"inline-block"
	}
});

function ListView(props) {
	const table_name = "ARCHIVED_SERVICE_REQUEST";
  	const classes = useStyles();
	const router = useRouter()
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [ displayButton, setDisplayButton ] = useState(false);
	const [ openRestoreConfirmModal, setOpenRestoreConfirmModal ] = useState(false)
	const [ restoreServiceTicketId, setRestoreServiceTicketId] = useState(0)
	const [ windowHeight, setWindowHeight ] = useState(window.innerHeight-330);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const service_request_id = useSelector((state) => state.ServiceRequestsApp.filterParamsReducer.service_request_id);
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("asset");
	const [serviceRequestId, setServiceRequestId] = useState(null)
	const [groupByArr, setGroupByArr] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const tableHeader = ["ticket_id", "serial_number", "location", "status", "subject_line", "detailed_description_of_the_issue"]

	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
			<Button 
				{...restProps} 
				onClick={() => tabStateChange(tab)} 
				className={restProps.className && isActive ? activeClass : ""}
			>
				{props.children}
			</Button>
		)
	}

	const handleClickOpen = (data) => {
		// setServiceRequestId(data.id)
		// setOpen(true)
		router.push(`/archive-service-tickets/${data.id}`)
	}
	
	function Close(){
		setOpen(false)
		setTabState('asset')
		dispatch(setServiceRequestID(null))
	}
	
	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}


	function formatString(str){
        if(!str) return ''
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }
	
	const onGridReady = (params) =>{
		setGridApi(params);
		props.serviceRequests.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(fieldName === 'archived_asset') continue;
				if((value === '-' || value === '')&& fieldName == 'user_name') 
					list[fieldName] = 'Unassigned'
				else if(value === '' || !value || value === '')
				  list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	function onFilterChanged(event){	 
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		// const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
		axios
		.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then((results) => {
			const { data } = results.data;
			if (data !== null) {
			const order = JSON.parse(data.order);
			const filter = JSON.parse(data.filter);
			params?.columnApi.applyColumnState({ state: order });
			params?.api.setFilterModel(filter);
			}
		})
		.catch((err) => {
			console.log(err);
		});
  	}
	function onSortChanged(params){
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
		}
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			if(router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event){
		if(router.query.report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			
			saveView(rows);
			dispatch(setrowGroupModel(rows));
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	const handleConfirmRestore = () => {
		if(restoreServiceTicketId !== 0) {
			axios.post(`/service/restore-service-request/${restoreServiceTicketId}`).then((res) => {
				props.fetchServiceReq()
				toast.success('Service ticket restored successfully.')
				setOpenRestoreConfirmModal(false)
			}).catch(err => {
				console.log('err',err);
				toast.error(err.response.data.message)
			})
		}
		
	}
	const handleCloseRestoreModal = () => {
		setRestoreServiceTicketId(0)
		setOpenRestoreConfirmModal(false)
	}

	const handleRestore = (id) => {
		setRestoreServiceTicketId(id)
		setOpenRestoreConfirmModal(true)
	}

	const generateHeaderLabel = (header) => {
		if(header == 'accessory_category_id'){
			return 'Accessory Category Name'
		} else if(header == 'manufacturer_id'){
			return 'Manufacturer Name'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	useEffect(()=>{
		if(service_request_id){
			setOpen(true)
			setServiceRequestId(service_request_id)
		}
	},[service_request_id]);

	useEffect(()=>{
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.serviceRequests])

	function redirectToAssetProfile(event){
		router.push(`/${event.data.archived_asset ? 'archived-assets': 'assets-list'}/${event.data.asset_id}`);
	}

	function handleHideColumnDropDownDesign(){
		return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
 	}

	return (
		<>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Archived Service Tickets'}
				displayTableName={'Archived Service Tickets'}
			/>	
			<div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'11px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.serviceRequests}
					rowHeight = {30}
					onRowClicked={() => {
					}}
					defaultColDef={{
						resizable: true
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged={onSortChanged}
					groupHeaderHeight ={1}
					// headerHeight = {15}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}
					// status bar enabled on range selection
					enableRangeSelection={ true }
					statusBar={{
						statusPanels: [
						{ 
							statusPanel: 'agAggregationComponent' },
						],
					}}

					// to check the the no. of rows selected during range selection
					onRangeSelectionChanged = { onRangeSelectionChanged }						

					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					pagination={true}
				>
					<AgGridColumn
						lockPosition={true}
						rowSelection={'single'}
						width={100}
						minWidth={100}
						maxWidth={100}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right'
						className="action_field" 
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{props.viewServiceRequestPermission && <Tooltip id="view" title="View" placement="top">
											{/* <Icon onClick={() => handleClickOpen(data)} fontSize='small' style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}} >visibility</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={() => handleClickOpen(data)} />
										</Tooltip>}

										{props.deleteServiceRequestPermission && <Tooltip id="unarchive" title="Restore" placement="top">
											{/* <Icon onClick={() => {handleRestore(data.id)}} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>unarchive_outlined</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-reload" onClick={() => handleRestore(data.id)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>
					{tableHeader.map(header => {
						if (header == 'status') {
							return (
								<AgGridColumn
								  field={header}
								  key={header}
								  minWidth={230}
								  cellEditorPopup
								  suppressSizeToFit
								  // cellEditor="agRichSelectCellEditor"
								  cellEditor='customDropdownEditor'
								  headerComponentFramework={CustomFilter}
								  sortable
								  
								  // floatingFilter
								  headerName={generateHeaderLabel(header)}
								  filter="text"
								  editable={false}
								  cellRendererFramework={(event) => {
									if (event.colDef.field === "status") {
									  return (
										<span
										  style={handleHideColumnDropDownDesign()}>
										  <span
											style={{
											  height: "10px",
											  width: "10px",
											  marginTop: "2px",
											  borderRadius: "50%",
											  display: "inline-block",
											  // backgroundColor: event.data?.asset_status_color?.code
											  backgroundColor:
												event.value?.split(",")[1] ??
												event.data?.status_details?.status_color?.code,
											}}
										  />
										  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
										  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
										  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
											{event.value?.split(",")[0]}
										  </span>
										</span>
									  );
									} if (
									  event.colDef.headerName == "Group" &&
									  event.node.field == "status"
									) {
									  return (
										<>
										  <span>{event.value}</span>
										</>
									  );
									}
									return (
									  <>
										<span>{event.value}</span>
									  </>
									);
				
								  }}
								/>
							  );
						}
						
						if (header == 'detailed_description_of_the_issue') {
							return (
								<AgGridColumn
									field={header}
									key={header}
									suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}
									sortable={true}
									minWidth={400}
									flex={1}
									headerComponentFramework={CustomFilter}
									filter="text"
								/>
							)
						}
						if (header == 'serial_number') {
							return (
								<AgGridColumn
									field={header}
									key={header}
									suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}
									sortable={true}
									headerComponentFramework={CustomFilter}
									filter="text"
									flex={1}
									minWidth={200}
									cellRendererFramework={(event) => {
										if(event.value === "No Serial Number"){
											return(<span>{event.value}</span>)
										}
										return (<a
											style={{
												height: '10px',
												width: '10px',
												color: 'black',
												cursor: 'pointer',
												marginTop: '2px',
												borderRadius: '50%',
												display: 'inline-block',
											}}
											onClick={() => redirectToAssetProfile(event)}
											variant="subtitle1"
										>
											{<span>{event.value}</span>}
										</a>)
									}}
								/>
							)
						}
						return (
							<AgGridColumn
								field={header}
								key={header}
								suppressSizeToFit={true}
								headerName={generateHeaderLabel(header)}
								sortable={true}
								flex={1}
								minWidth={200}
								headerComponentFramework={CustomFilter}
								filter="text"
							/>
						)
					})}
				</AgGridReact>
			</div>

			{
				openRestoreConfirmModal && (
					<GeneralModal
						open={openRestoreConfirmModal}
						title={"Are you sure to restore service ticket?"}
						text={
							<h4>
								Are you sure to Restore this Service Ticket?
								{/* <b>{modalData.ticket_number}</b>? */}
							</h4>
						}
						detailMessage={"Your ticket will be moved to service tickets if you proceed"}
						handleClose={handleCloseRestoreModal}
						handleConfirm={handleConfirmRestore}
						actionButtonName="Yes, Proceed!"
					/>
				)
			}

			{open && <ServiceRequestDailog 
				fullWidth={fullWidth} 
				maxWidth={maxWidth} 
				open={open} 
				setOpen ={setOpen} 
				Close={Close} 
				classes={classes} 
				TabButton ={TabButton} 
				tabState={tabState} 
				setTabState={setTabState} 
				TabContainer={TabContainer}
				serviceRequestId={serviceRequestId}
			/>}
		</>
	);
}

export default ListView